import apiClient, { apiOpenClient } from '@/services/axios'

export const getChannelWarehouse = ({ business_id, channel_code, channel_id, connected }) => {
  const path1 = connected ? 'map/' : ''
  const path2 = channel_code.includes('blibli')
    ? 'channel'
    : `${path1}list`
  const url = channel_code.includes('lazada')
    ? `${channel_id}/warehouse`
    : `query/warehouse/${path2}/${channel_id}`
  
  return apiClient({
    method: 'get',
    url: channel_code.includes('shopee')
      ? `/shopee-int/warehouse/map/list/${channel_id}`
      : `/channel/${channel_code}/integration/${url}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getChannelCategories = ({ business_id, channel_code, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/categories`,
    headers: {
      'Business-Id': business_id,
    },
    params: { business_id },
  })
}

export const getChannelAttributes = ({ business_id, channel_code, channel_id, channel_type, params }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/${channel_type}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const channelEventProcess = ({ business_id, channel_code, channel_id, request_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/response/${request_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const channelImageDraft = ({ business_id, channel_code, channel_id, user_id, product_id, params }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/${user_id}/draft/images/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const checkStatusSyncOrderChannel = ({ business_id, channel_code, channel_id }) => {
  if (['shopee_id'].includes(channel_code)) {
    return apiClient({
      method: 'get',
      url: `/channel/${channel_code}/order/query/synchronize/${channel_id}/status`,
      headers: {
        'Business-Id': business_id,
      },
    })
  } else if (['lazada_id'].includes(channel_code)) {
    return apiClient({
      method: 'get',
      url: `/channel/${channel_code}/order/${channel_id}/synchronize/status`,
      headers: {
        'Business-Id': business_id,
      },
    })
  } else {
    return false
  }
}

export const getWarehouseChannelList = ({ sales_channel, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${sales_channel}/integration/query/warehouse/list/${channel_id}`,
  })
}

export const getConnectedWarehouseChannelList = ({ sales_channel, channel_id, business_id }) => {
  const url = sales_channel === 'blibli'
    ? 'channel'
    : 'list'
  return apiClient({
    method: 'get',
    url: `/channel/${sales_channel}/integration/query/warehouse/${url}/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const channelSyncProcess = ({ business_id, channel_code, channel_id, request_id }) => {
  const url = channel_code.includes('blibli')
    ? 'request'
    : channel_code === 'lazada_id'
    ? 'synchronize/status'
    : 'sync'
  const path = channel_code === 'lazada_id' ? '' : '/query'

  return apiClient({
    method: 'get',
    url: `/channel/${channel_code.includes('blibli') ? 'blibli' : channel_code}/product${path}/${channel_id}/${url}/${request_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getCatalogByMaster = ({ signal, business_id, channel_code, channel_id, params }) => {
  return apiClient({
    signal,
    method: 'get',
    url: `/channel/${channel_code}/product/query/${channel_id}/catalogs`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getDetailProductChannel = ({ business_id, channel_code, channel_id, product_id }) => {
  const code = channel_code === 'blibli_id' ? 'blibli' : channel_code
  const path = channel_code === 'shopee_id' ? '/details' : ''
  const url = channel_code === 'blibli_id' ? `${product_id}/${channel_id}` : `${channel_id + path}/${product_id}`

  return apiClient({
    method: 'get',
    url: `/channel/${code}/product/query/${url}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getProductChannelStatus = ({ channel_code, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/${channel_code}/product/query/${channel_id}/status`,
  })
}

export const getProductConfirm = ({ channel_code, channel_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/${channel_code}/product/query/${channel_id}/catalog`,
    params,
  })
}

export const getCancelReason = ({ business_id, channel_code, params }) => {
  return apiClient({
    method: 'get',
    url: `/channel/${channel_code}/order/query/cancellation-reasons`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getCmCancelReason = ({ business_id, order_number }) => {
  return apiClient({
    method: 'get',
    url: `/sales-manual/cancellation-reasons?order_number=${order_number}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const syncProductChannel = ({ business_id, channel_code, channel_id, user_id, channel_product_id, data }) => {
  const url = ['blibli', 'tiktok'].includes(channel_code)
    ? 'sync'
    : channel_code === 'lazada_id'
    ? 'synchronize'
    : `${user_id}import`
  const isCmd = channel_code === 'lazada_id'
    ? ''
    : 'cmd/'
  const productId = channel_product_id
    ? `/${channel_product_id}`
    : ''
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/${isCmd}${channel_id}/${url + productId}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
    notifications: true,
  })
}

export const mappingProductChannel = ({ business_id, channel_code, channel_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/mapping`,
    headers: {
      'Business-Id': business_id,
    },
    data,
    notifications: true,
  })
}

export const importProductChannel = ({ is_single, business_id, channel_code, channel_id, data }) => {
  const url = channel_code.includes('blibli')
    ? `/channel/blibli/product/cmd/import/${is_single}master/product`
    : `/channel/${channel_code}/product/cmd/${channel_id}/import/master/product`

  return apiClient({
    method: 'post',
    url,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const channelProductDraft = ({ business_id, channel_code, channel_id, user_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/${user_id}/draft`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const uploadChannelImage = ({ business_id, channel_code, channel_id, user_id, product_id, file, image_type }) => {
  const id = !channel_code.includes('shopee')
    ? `/${product_id}`
    : ''
  const params = channel_code.includes('shopee') || channel_code.includes('tokopedia')
    ? { image_type }
    : undefined
  const form = new FormData()
  form.append('image', file)

  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/${user_id}/upload/image${id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Business-Id': business_id,
    },
    data: form,
    params,
  })
}

export const addDeleteImages = ({ type = undefined, business_id, channel_id, product_id, file, images_existing }) => {
  const form = new FormData()
  if(type) {
    if(images_existing.length) form.append('images_existing', images_existing.join(','))
    file.forEach(item => form.append('images', item))
  } else {
    form.append('images', file)
    form.append('images_existing', images_existing)
  }
  
  form.append('business_id', business_id)
  form.append('product_id', product_id)

  return apiClient({
    method: 'post',
    url: `/channel/distributor/product/cmd/${channel_id}/add-delete-images`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Business-Id': business_id,
    },
    data: form,
    params: { business_id },
  })
}

export const addChannelProduct = ({ method = 'post', business_id, channel_code, channel_id, user_id, product_id, data }) => {
  const create = channel_code.includes('shopee') || channel_code.includes('tiktok') ? 'create/' : ''
  return apiClient({
    method,
    url: `/channel/${channel_code}/product/cmd/${create + channel_id}/${user_id}/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const getChannelDraft = ({ business_id, channel_code, channel_id, user_id }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/${user_id}/draft/`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const syncChannelAttributes = ({ business_id, channel_code, channel_id, path, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/import/${path}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const onSyncOrderChannel = async ({ business_id, channel_code, channel_id, payload }) => {
  if (['shopee_id'].includes(channel_code)) {
    return apiClient({
      method: 'post',
      url: `/channel/${channel_code}/order/cmd/${channel_id}/synchronize/order`,
      data: payload,
      headers: {
        'Business-Id': business_id,
      },
    })
  } else if (['tokopedia_id', 'tiktok'].includes(channel_code)) {
    return apiClient({
      method: 'post',
      url: `/channel/${channel_code}/order/cmd/${channel_id}/orders/sync`,
      data: payload,
      headers: {
        'Business-Id': business_id,
      },
    })
  } else if (['lazada_id'].includes(channel_code)) {
    return apiClient({
      method: 'post',
      url: `/channel/${channel_code}/order/${channel_id}/synchronize`,
      data: payload,
      headers: {
        'Business-Id': business_id,
      },
    })
  } else {
    return false
  }
}

export const acceptFulfillmentOrder = ({ channel_code, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/order/cmd/accept`,
    data,
  })
}

export const cancelFulfillmentOrder = ({ business_id, channel_code, channel_id, order_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/order/cmd/${channel_id}/${order_id}/cancel`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const connectedChannel = ({ channel_code, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/${channel_code}/integration/cmd/connect`,
    data,
  })
}

export const addNewSku = ({ channel_code, channel_id, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/${channel_code}/${channel_id}/catalog/sku`,
    data,
  })
}

export const disconnectChannelWithPause = ({ business_id, channel_code, channel_id }) => {
  return apiClient({
    method: 'put',
    url: `/channel/${channel_code}/integration/cmd/disconnect`,
    params: {
      channel_id,
    },
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const reconnectChannelWithPause = ({ business_id, channel_code, channel_id }) => {
  return apiClient({
    method: 'put',
    url: `/channel/${channel_code}/integration/cmd/reconnect`,
    params: {
      channel_id,
    },
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const activateDeactivateProductChannel = ({ business_id, channel_code, channel_id, user_id, product_id, data }) => {
  return apiClient({
    method: 'put',
      url: `channel/${channel_code}/product/cmd/edit/${channel_id}/${user_id}/${product_id}`,
      headers: {
        'Business-Id': business_id,
      },
      data,
  })
}

export const updateProductChannel = ({ business_id, channel_code, channel_id, user_id, product_id, data }) => {
  return apiClient({
    method: 'put',
    url: `/channel/${channel_code}/product/cmd${['tiktok', 'tiktok_id'].includes(channel_code) ? '' : '/edit'}/${channel_id}/${user_id}/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const updateProductChannelStatus = ({ method, business_id, channel_code, channel_id, status, data }) => {
  return apiClient({
    method,
    url: `/channel/${channel_code}/product/cmd/${channel_id}/${status}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const processingOrder = ({ business_id, processType, id, channel_id, channel_code, order_number, ...data }) => {
  return apiClient({
    method: channel_code === 'blibli_id' ? 'post' : 'put',
    url: channel_code === 'blibli_id'
      ? `channel/blibli/order/cmd/accept/single/${channel_id}`
      : `/channel/${channel_code}/order/cmd/${processType}`,
    headers: {
      'Business-Id': business_id,
    },
    data: channel_code === 'blibli_id'
      ? { order_id: id }
      : data,
    params: channel_code === 'blibli_id'
      ? undefined
      : channel_code === 'lazada_id'
      ? { order_id: id }
      : { business_id, id, order_number },
  })
}

export const processingOrderState = ({ business_id, order_id, data, order_number }) => {
  return apiClient({
    method: 'put',
    url: `/sales-manual/state?order_number=${order_number}&id=${order_id}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const cancelOrderState = ({ business_id, order_id, data, order_number }) => {
  return apiClient({
    method: 'put',
    url: `/sales-manual/cancel?order_number=${order_number}&id=${order_id}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const resynchronizeOrder = ({ business_id, channel_code, order_id }) => {
  return apiClient({
    method: 'patch',
    url: `/channel/${channel_code}/order/cmd/orders/${order_id}/sync-booking-number`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const deleteChannelDraft = async ({ business_id, channel_code, channel_id, user_id, product_id }) => {
  await apiClient({
    method: 'delete',
    url: `/channel/${channel_code}/product/cmd/${channel_id}/${user_id}/draft/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const internalCancelOrderState = ({ business_id, data }) => {
  return apiOpenClient({
    method: 'post',
    url: `/internal/canceled`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const disconnecMappingWarehouseChannel = ({ business_id, channel_code, data }) => {
  return apiClient({
    method: 'delete',
    url: `/channel/${channel_code}/integration/cmd/warehouse/map`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const connectMappingWarehouseChannel = ({ business_id, channel_code, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/${channel_code}/integration/cmd/warehouse/map/multi`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const updateMappingWarehouseChannel = ({ business_id, channel_code, mapping_id, data }) => {
  return apiClient({
    method: 'put',
    url: `/channel/${channel_code}/integration/cmd/warehouse/map/${mapping_id}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}